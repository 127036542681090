import { IconName } from '~/assets';
import { routes } from '~/utils';
import { match, SearchResult } from './QuickSearch';
import { useHistory } from 'react-router-dom';
import { Icon } from '~/components';
import { CommandItem } from '../Command';

interface CommandAction {
  name: string;
  icon: IconName;
  paths: string[];
  href: string;
}

export function useNavigationSearch(): SearchResult<CommandAction> {
  const history = useHistory();

  const navigationItems: CommandAction[] = [
    {
      name: 'Dashboard',
      icon: 'Pulse',
      href: routes.dashboard,
      paths: ['dashboard']
    },
    {
      name: 'Models',
      icon: 'Model',
      href: routes.models,
      paths: ['models']
    },
    {
      name: 'Model syncs',
      icon: 'Syncs',
      href: routes.syncs,
      paths: ['syncs', 'model syncs']
    },
    {
      name: 'Bulk syncs',
      icon: 'Truck',
      href: routes.bulkSyncsRoot,
      paths: ['bulk syncs']
    },
    {
      name: 'Connections',
      icon: 'Generic',
      href: routes.connections,
      paths: ['connections']
    },
    {
      name: 'Query runner',
      icon: 'Terminal',
      href: routes.queryRunner,
      paths: ['query runner']
    },
    {
      name: 'Explore',
      icon: 'Search',
      href: routes.explore,
      paths: ['explore']
    },
    {
      name: 'Team',
      icon: 'Team',
      href: routes.team,
      paths: ['team']
    },
    {
      name: 'Settings',
      icon: 'Gear',
      href: routes.user,
      paths: ['settings']
    }
  ];

  return {
    title: 'Navigation',
    search: search => navigationItems.filter(i => !search || match(i.paths, search)),
    render: (navigation, handleSelect) => (
      <CommandItem
        key={navigation.name}
        className="flex items-center gap-2"
        onSelect={handleSelect(() => {
          history.push(navigation.href);
        })}
      >
        <Icon name={navigation.icon} className="text-gray-500" />
        <span>{navigation.name}</span>
      </CommandItem>
    )
  };
}
