import { IconName } from '~/assets';
import { routes } from '~/utils';
import { match, SearchResult } from './QuickSearch';
import { useHistory } from 'react-router-dom';
import { Icon } from '~/components';
import { CommandItem } from '../Command';

interface CommandAction {
  name: string;
  icon: IconName;
  paths: string[];
  href: string;
}

export function useActionSearch(): SearchResult<CommandAction> {
  const history = useHistory();

  const actionItems: CommandAction[] = [
    {
      name: 'New model',
      icon: 'PlusCircle',
      href: routes.createModel,
      paths: ['new model', 'create model']
    },
    {
      name: 'New model sync',
      icon: 'PlusCircle',
      href: routes.createSync,
      paths: ['new sync', 'create sync', 'new model sync', 'create model sync']
    },
    {
      name: 'New bulk sync',
      icon: 'PlusCircle',
      href: routes.createBulkSync,
      paths: ['new bulk sync', 'create bulk sync']
    }
  ];

  return {
    title: 'Actions',
    search: search => actionItems.filter(i => !search || match(i.paths, search)),
    render: (action, handleSelect) => (
      <CommandItem
        key={action.name}
        className="flex items-center gap-2"
        onSelect={handleSelect(() => {
          history.push(action.href);
        })}
      >
        <Icon name={action.icon} className="text-gray-500" />
        <span>{action.name}</span>
      </CommandItem>
    )
  };
}
