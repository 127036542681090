import { useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router-dom';
import { BulkSyncsDocument } from '~/generated/graphql';
import { routes } from '~/utils';
import { Icon, Truncator } from '~/components';
import { match, SearchResult } from '..';
import { CommandItem } from '../Command';

type BulkSyncResult = {
  id: string;
  name: string;
  source?: {
    connection: {
      type: {
        id: string;
      };
    };
  };
  destination?: {
    connection: {
      type: {
        id: string;
      };
    };
  };
};

export function useBulkSyncsSearch(): SearchResult<BulkSyncResult> {
  const history = useHistory();
  const { data } = useQuery(BulkSyncsDocument);

  return {
    title: 'Bulk syncs',
    search: search =>
      (data?.bulkSyncs ?? []).filter(
        s =>
          search &&
          match(
            [s.id, s.name, s.source?.connection?.type?.name, s.destination?.connection?.type?.name],
            search
          )
      ),
    render: (sync, handleSelect) => (
      <CommandItem
        key={sync.id}
        value={sync.id}
        onSelect={handleSelect(() => history.push(generatePath(routes.bulkSync, { id: sync.id })))}
        className="flex items-center gap-2"
      >
        <div className="flex items-center gap-1">
          <Icon match={sync.source.connection.type.id} />
          <Icon name="Syncs" />
          <Icon match={sync.destination.connection.type.id} />
        </div>
        <Truncator content={sync.name}>
          <span className="truncate">{sync.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
