import { useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router-dom';
import { ConnectionsDocument } from '~/generated/graphql';
import { routes } from '~/utils';
import { Icon, Truncator } from '~/components';
import { match, SearchResult } from '..';
import { CommandItem } from '../Command';

interface ConnectionResult {
  id: string;
  name: string;
  type: {
    id: string;
  };
}

export function useConnectionsSearch(): SearchResult<ConnectionResult> {
  const history = useHistory();
  const { data } = useQuery(ConnectionsDocument);

  return {
    title: 'Connections',
    search: search =>
      (data?.connections ?? []).filter(c => search && match([c.id, c.name], search)),
    render: (connection, handleSelect) => (
      <CommandItem
        key={connection.id}
        value={connection.id}
        onSelect={handleSelect(() =>
          history.push(generatePath(routes.editConnection, { id: connection.id }))
        )}
        className="flex items-center gap-2"
      >
        <Icon match={connection.type.id} />
        <Truncator content={connection.name}>
          <span className="truncate">{connection.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
