import { useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router-dom';
import { ModelDocument } from '~/generated/graphql';
import { match, SearchResult } from './QuickSearch';
import { routes } from '~/utils';
import { Icon, Truncator } from '~/components';
import { CommandItem } from '../Command';

interface ModelResult {
  id: string;
  name: string;
  connection: {
    type: {
      id: string;
    };
    name: string;
  };
}

export function useModelSearch(): SearchResult<ModelResult> {
  const history = useHistory();
  const { data } = useQuery(ModelDocument);

  return {
    title: 'Models',
    search: search =>
      (data?.model?.fieldsets ?? []).filter(
        m => search && match([m.id, m.name, m.connection.name], search)
      ),
    render: (model, handleSelect) => (
      <CommandItem
        key={model.id}
        value={model.id}
        onSelect={handleSelect(() =>
          history.push(generatePath(routes.editModel, { fieldsetId: model.id }))
        )}
        className="flex w-full min-w-0 items-center gap-2"
      >
        <Icon match={model.connection.type.id} />
        <Truncator content={model.connection.name}>
          <span className="min-w-[50px] max-w-fit shrink-0 grow basis-0 truncate">
            {model.connection.name}
          </span>
        </Truncator>
        <Icon name="Disclosure" className="text-gray-500" />
        <Truncator content={model.name}>
          <span className="max-w-fit grow truncate">{model.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
