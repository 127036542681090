import { useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router-dom';
import { PeekSyncsDocument } from '~/generated/graphql';
import { routes } from '~/utils';
import { Icon, Truncator } from '~/components';
import { match, SearchResult } from '..';
import { Fragment } from 'react';
import { CommandItem } from '../Command';

type SyncResult = {
  id: string;
  name: string;
  SourceConnections: {
    ConnectionID: string;
    ConnectionType: string;
  }[];
  TargetConnectionType: string;
};

export function useSyncsSearch(): SearchResult<SyncResult> {
  const history = useHistory();
  const { data } = useQuery(PeekSyncsDocument);

  return {
    title: 'Model syncs',
    search: search =>
      (data?.peekSyncs ?? []).filter(
        s =>
          search &&
          match(
            [
              s.id,
              s.name,
              ...s.SourceConnections.map(c => c.ConnectionType),
              s.TargetConnectionType
            ],
            search
          )
      ),
    render: (sync, handleSelect) => (
      <CommandItem
        key={sync.id}
        value={sync.id}
        onSelect={handleSelect(() => history.push(generatePath(routes.sync, { id: sync.id })))}
        className="flex items-center gap-2"
      >
        <div className="flex items-center gap-1">
          {sync.SourceConnections.map((connection, i) => (
            <Fragment key={connection.ConnectionID}>
              {i > 0 && <Icon name="PlusSmall" className="-mx-1.5" />}
              <Icon match={connection.ConnectionType} />
            </Fragment>
          ))}
          <Icon name="Syncs" />
          {sync.TargetConnectionType && (
            <Icon match={sync.TargetConnectionType} className="mr-1.5" />
          )}
        </div>
        <Truncator content={sync.name}>
          <span className="truncate">{sync.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
