import { generatePath, useHistory } from 'react-router-dom';
import { Icon, Truncator } from '~/components';
import { ModelField, useModelFields } from '~/hooks';
import { routes } from '~/utils';
import { match, SearchResult } from './QuickSearch';
import { CommandItem } from '../Command';

interface FieldResult {
  id?: string;
  name?: string;
  sourceName: string;
  fieldset: {
    id: string;
    name: string;
    connection: {
      name: string;
      type: {
        id: string;
      };
    };
  };
  enrichmentFieldset?: {
    id?: string;
    connection?: {
      type: {
        id: string;
      };
    };
  };
}

export function useFieldSearch(): SearchResult<FieldResult> {
  const history = useHistory();
  const { modelFields } = useModelFields();

  return {
    title: 'Model fields',
    search: search =>
      (modelFields as ModelField[]).filter(
        f => search && match([f.id, f.label, f.sourceName, `${f.fieldset.name} ${f.label}`], search)
      ),
    render: (field, handleSelect) => (
      <CommandItem
        key={field.id}
        value={field.id}
        onSelect={handleSelect(() =>
          history.push(
            generatePath(routes.editField, {
              fieldId: field.id,
              fieldsetId: field.fieldset.id
            })
          )
        )}
        className="flex items-center gap-2"
      >
        <Icon
          match={
            field.enrichmentFieldset?.connection?.type?.id ?? field.fieldset.connection.type.id
          }
        />
        <div className="flex w-full min-w-0 items-center gap-1">
          <Truncator content={field.fieldset.connection.name}>
            <span className="min-w-[50px] max-w-fit shrink-0 grow basis-0 truncate">
              {field.fieldset.connection.name}
            </span>
          </Truncator>
          <Icon name="Disclosure" className="text-gray-500" />
          <Truncator content={field.fieldset.name}>
            <span className="min-w-[50px] max-w-fit shrink-0 grow basis-0 truncate">
              {field.fieldset.name}
            </span>
          </Truncator>
          <Icon name="Disclosure" className="text-gray-500" />
          <Truncator content={field.sourceName}>
            <span className="max-w-fit grow truncate">{field.sourceName}</span>
          </Truncator>
        </div>
      </CommandItem>
    )
  };
}
